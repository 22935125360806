const columnConfig = {
  series: [],
  chart: {
    height: 350,
    type: "bar",
    foreColor: "#000000",
  },
  xaxis: {
    categories: [],
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    title: {
      text: "Citas",
    },
    min: 0,
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      dataLabels: {
        position: "top", // top, center, bottom
      },
    },
  },

  tooltip: {
    y: {
      formatter(y) {
        if (typeof y !== "undefined") {
          return y.toFixed(0);
        }
        return y;
      },
    },
  },
};

export default columnConfig;
