<template>
  <div>
    <div class="titles my-5" v-text="'Confirmaciones'"></div>
    <div
      class="mb-4 metrics__graph--shadow"
      id="confirmsChart"
      ref="confirmsChart"></div>
    <div class="titles my-5" v-text="'Citas'"></div>
    <div
      class="metrics__graph--shadow"
      id="statusChart"
      ref="statusChart"></div>
  </div>
</template>

<script>
import chartMultiColumnConfig from "@/constants/metrics/chartMultiColumnConfig";
import ApexCharts from "apexcharts";

export default {
  name: "GraphicsMedic",
  data() {
    return {
      chartMultiColumnConfig,
    };
  },
  mounted() {
    this.$emit("updateData");
  },
  methods: {
    loadData(payload, onMounted = false) {
      this.getConfirmsInfo(payload, onMounted);
      this.getStatusInfo(payload, onMounted);
    },
    getStatusInfo(payload, onMounted = false) {
      this.$api.metrics.getDataMedicStatus(payload).then((res) => {
        this.plothChartStatus(res.data.medics, onMounted);
      });
    },
    getConfirmsInfo(payload, onMounted = false) {
      this.$api.metrics.getDataMedicConfirms(payload).then((res) => {
        this.plothChartConfirmations(res.data.medics, onMounted);
      });
    },
    chartColumnGenerator(onMounted = false, chartRef, options, chartName) {
      if (!onMounted && this[chartName]) this[chartName].destroy();
      this[chartName] = new ApexCharts(chartRef, options);
      this[chartName].render();
    },
    plothChartConfirmations(plotData, onMounted = false) {
      const chartConfig = Object.assign({}, this.chartMultiColumnConfig);
      const series = [
        {
          name: "Total",
          type: "column",
          data: plotData.map((x) => x.total),
        },
        {
          name: "Confirmadas con Nota Médica",
          type: "column",
          data: plotData.map((x) => x.with_note),
        },
        {
          name: "Confirmadas sin Nota Médica",
          type: "column",
          data: plotData.map((x) => x.without_note),
        },
      ];
      const labels = plotData.map((x) => x.name);
      chartConfig.series = series;
      chartConfig.labels = labels;
      chartConfig.tooltip.x = {
        formatter(x, dataOnMove) {
          const index = dataOnMove.dataPointIndex;
          const infoByUser = plotData[index];
          return `${infoByUser.name}`;
        },
      };
      chartConfig.xaxis.categories = plotData.map((x) => x.name);
      this.chartColumnGenerator(
        onMounted,
        this.$refs.confirmsChart,
        chartConfig,
        "confirmsChart"
      );
    },
    plothChartStatus(plotData, onMounted = false) {
      const chartConfig = Object.assign({}, this.chartMultiColumnConfig);
      const series = [
        {
          name: "Total",
          type: "column",
          data: plotData.map((x) => x.total),
        },
        {
          name: "Pendientes",
          type: "column",
          data: plotData.map((x) => x.untaken),
        },
        {
          name: "Completadas",
          type: "column",
          data: plotData.map((x) => x.taken),
        },
        {
          name: "Canceladas",
          type: "column",
          data: plotData.map((x) => x.cancelled),
        },
      ];
      const labels = plotData.map((x) => x.name);
      chartConfig.colors = ["#284bbf", "#f7b924", "#2ecc71", "#ff0a00"];
      chartConfig.series = series;
      chartConfig.labels = labels;
      chartConfig.tooltip.x = {
        formatter(x, dataOnMove) {
          const index = dataOnMove.dataPointIndex;
          const infoByUser = plotData[index];
          return `${infoByUser.name}`;
        },
      };
      chartConfig.xaxis.categories = plotData.map((x) => x.name);
      this.chartColumnGenerator(
        onMounted,
        this.$refs.statusChart,
        chartConfig,
        "statusChart"
      );
    },
  },
};
</script>
